import { createAuthClient } from "better-auth/client";

export const authClient = createAuthClient();

export const signIn = async () => {
  try {
    await authClient.signIn.social({
      provider: "google",
    });
  } catch (error) {
    console.error(error);
  }
};

export const getSession = async () => {
  const data = await authClient.getSession();

  return data;
};

export const signOut = async () => {
  await authClient.signOut();
  window.location.reload();
};
