import { getSession } from "@/lib/auth-client";
import { userAtom } from "@/store/auth";
import { useAtom } from "jotai";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [, setUser] = useAtom(userAtom);

  const {
    data: session,
    isError,
    error,
  } = useQuery({
    queryKey: ["session"],
    queryFn: getSession,
    // 5 minutes
    refetchInterval: 5 * 60 * 1000,
    retry: 3,
    staleTime: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (session?.data?.user) {
      setUser(session.data?.user || null);
    } else {
      setUser(null);
    }
  }, [session]);

  useEffect(() => {
    if (isError) {
      setUser(null);
    }
  }, [isError]);

  return <>{children}</>;
}
