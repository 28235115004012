import { atom } from "jotai";

export type ChatMessage = {
  id: string;
  content: string;
  role: "user" | "assistant";
  timestamp: Date;
};

export type Model = {
  id: string;
  name: string;
  provider: "openai" | "anthropic" | "gemini";
};

export const selectedModelAtom = atom<Model>({
  id: "gpt-4",
  name: "GPT-4",
  provider: "openai",
});

export const chatHistoryAtom = atom<ChatMessage[]>([]);
