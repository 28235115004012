import "./style.css";

import "./tailwind.css";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { chatHistoryAtom } from "@/store/atoms";
import { userAtom } from "@/store/auth";
import { useAtom } from "jotai";
import React from "react";

// import { AuthProvider } from "@/components/AuthProvider";
import { TRPCProvider } from "@/components/TRPCProvider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { AuthProvider } from "@/components/AuthProvider";
import { signOut } from "@/lib/auth-client";

interface LayoutDefaultProps {
  children: React.ReactNode;
}

export default function LayoutDefault({ children }: LayoutDefaultProps) {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [chatHistory] = useAtom(chatHistoryAtom);
  const [user] = useAtom(userAtom);

  return (
    <TRPCProvider>
      <AuthProvider>
        <TooltipProvider delayDuration={0}>
          <ResizablePanelGroup direction="horizontal" className="min-h-screen items-stretch">
            <ResizablePanel
              defaultSize={20}
              collapsible={true}
              minSize={15}
              maxSize={25}
              collapsedSize={4}
              onCollapse={() => setIsCollapsed(true)}
              onExpand={() => setIsCollapsed(false)}
              className={cn("flex flex-col", isCollapsed && "min-w-[50px] transition-all duration-300 ease-in-out")}
            >
              <div className="flex h-[52px] items-center justify-between px-2">
                <Button variant="ghost" className="h-10 flex-1 justify-start px-2 hover:bg-muted">
                  {isCollapsed ? "+" : "New Chat"}
                </Button>
                {user && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Avatar className="h-8 w-8 cursor-pointer">
                        {user.image && <AvatarImage src={user.image} />}
                        <AvatarFallback>{user.email?.[0].toUpperCase()}</AvatarFallback>
                      </Avatar>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={() => signOut()}>Sign out</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
              <Separator />
              <ScrollArea className="flex-1">
                <div className="p-2 space-y-2">
                  {chatHistory.map((chat) => (
                    <Button key={chat.id} variant="ghost" className="w-full justify-start px-2 hover:bg-muted">
                      <span className="truncate">{chat.content}</span>
                    </Button>
                  ))}
                </div>
              </ScrollArea>
            </ResizablePanel>
            <ResizableHandle withHandle />
            <ResizablePanel defaultSize={80} className="p-0">
              {children}
            </ResizablePanel>
          </ResizablePanelGroup>
        </TooltipProvider>
      </AuthProvider>
    </TRPCProvider>
  );
}
